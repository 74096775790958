import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import { useCookies } from 'react-cookie'

import Button from '../components/Button'
import P from '../components/P'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	padding-top: 1rem;
	padding-bottom: 2rem;
`

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Toggle = styled.div`
  width: 5rem;
  height: 2.5rem;
  margin: 2rem 0;
  font-family: RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: .9rem;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  background-color: ${props => props.theme.colors.black};
  overflow: hidden;
  transition: background-color 0.4s ease-in-out;
  cursor: pointer;
`

const ToggleText = styled.div`
	color: ${(props) => props.theme.colors.white};
	position: absolute;
	top: 50%;
	margin-top: -9px;
	${(props) =>
		props.toggleState
			? `
        left: 15px;
      `
			: `
        right: 15px;
      `};
`

const ToggleInput = styled.input`
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 9;
	cursor: pointer;
`

const ToggleRoundButton = styled.div`
	position: absolute;
	width: 30px;
	height: 30px;
	background-color: ${(props) => props.theme.colors.white};
	border-radius: 50%;
	display: inline-block;
	position: absolute;
	${(props) =>
		props.toggleState
			? `
        right: 5px;
      `
			: `
        left: 5px;
      `} top: 50%;
	margin-top: -15px;
	transition: all 0.3s ease-in-out;
`

const StyledButton = styled(Button)`
  padding: 1.3rem;
  min-width: 10.625rem;
`

const SuccessMessage = styled.div`
	position: absolute;
	right: 0;
	bottom: 15px;
	text-align: right;
`

const AnalyticsToggleButton = () => {
	const [ cookies, setCookie, removeCookie ] = useCookies([
		'cookies_preferences_set',
		'cookies_policy',
		`ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`
	])
	const [ isAnalyticsActive, setIsAnalyticsActive ] = React.useState(_get(cookies, 'cookies_policy.analytics', false))
	const [ message, setMessage ] = React.useState('')

	const handleOnSaveClick = () => {
		if (!_get(cookies, 'cookies_preferences_set')) {
			setCookie('cookies_preferences_set', true, { path: '/' })
		}
		setCookie(
			'cookies_policy',
			{
				...cookies.cookies_policy,
				analytics: isAnalyticsActive
			},
			{ path: '/' }
		)
		// restore analytics tracking if analytics cookie is set to: track
		if (isAnalyticsActive && _get(cookies, `ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`)) {
			// remove disable analytics cookie
			removeCookie(`ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`, { path: '/' })
		}

		setMessage('Your cookie settings were saved')
	}

	const handleAnalyticsToggle = () => {
		setMessage('')
		setIsAnalyticsActive(!isAnalyticsActive)
	}

	React.useEffect(
		() => {
			// disable analytics tracking if analytics cookie is set to: not track
			if (
				!_get(cookies, 'cookies_policy.analytics') &&
				!_get(cookies, `ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`)
			) {
				// this cookie is used by GA to disable analytics
				setCookie(`ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`, true, { path: '/' })
			}
		},
		[ cookies ]
	)

	return (
		<Container>
			<Wrapper>
				<Toggle onClick={handleAnalyticsToggle}>
					<ToggleText toggleState={isAnalyticsActive}>{isAnalyticsActive ? 'On' : 'Off'}</ToggleText>
					<ToggleInput type="checkbox" checked={isAnalyticsActive} />
					<ToggleRoundButton toggleState={isAnalyticsActive} />
				</Toggle>
				<StyledButton onClick={handleOnSaveClick} variant="primary">
					Save
				</StyledButton>
			</Wrapper>
			{message && (
				<SuccessMessage>
					<P>{message}</P>
				</SuccessMessage>
			)}
		</Container>
	)
}

export default AnalyticsToggleButton
