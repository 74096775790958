import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _size from 'lodash/size'

import Container from '../components/Container'
import Layout from '../components/layout'
import SEO from '../components/seo'
import AnalyticsToggleButton from '../components/AnalyticsToggleButton'
import Table from '../components/Table'
import H1 from '../components/H1'
import H3 from '../components/H3'
import P from '../components/P'

import media from '../styles/media'
import theme from '../styles/theme'

export const query = graphql`
query LegalQuery($uid: String) {
  prismic {
    allLegals(uid: $uid) {
      edges {
        node {
          ... LegalFragment
        }
      }
    }
  }
}
`

const CookiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 2rem;
`

const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.greaterThan('tablet')`
    flex-direction: row;
  `}
  ${media.greaterThan('desktop')`
    flex-direction: row;
  `}
`

const LeftColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  flex: 1;
  width: 100%;
  ${media.greaterThan('tablet')`
    flex: .6;
  `}
  ${media.greaterThan('desktop')`
    flex: .6;
  `}
`

const RightColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  padding-top: 0rem;
  padding-left: 0rem;
  
  ${media.greaterThan('tablet')`
    flex: .4;
    padding-top: 3rem;
    padding-left: 5rem;
  `}
`

const TextWrapper = styled.div`
  padding-bottom: 1.5rem;
  ${media.greaterThan('mobile')`
    padding-bottom: 2rem;
    padding-top: 1.55rem;
  `}
`

const ColoredLineBreak = styled.div`
  width: 100%;
  max-width: 115px;
  height: 3px;
  background: ${props => props.light ? theme.colors.white : theme.colors.dark};
  margin-bottom: 1rem;
  -webkit-transition: max-width 0.25s ease-in-out;
  transition: max-width 0.25s ease-in-out;
`

const ColumnOne = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${media.greaterThan('mobile')``}
`

const MainContent = ({ section }) => {
  return (
    <ColumnOne>
      {_map(section, ({ subtitle, body, content }, index) => (
        <div key={index}>
          <H3 variant='boxTitle'>{subtitle}</H3>
          <ColoredLineBreak dark />
          <TextWrapper>
            <P variant='primary'>{body}</P>
            {RichText.render(content)}
          </TextWrapper>
        </div>
      ))}
    </ColumnOne>
  )
}

const CookiesSection = ({ section, necessaryCookiesTable, table }) => {
  const Content = styled.div`
    margin-bottom: 1.8rem;
  `

  const columns = [
    { key: 'cookie_type', value: 'Cookie', colspan: 2 },
    { key: 'cookie_name', value: 'Name', colspan: 1 },
    { key: 'cookie_purpose', value: 'Purpose', colspan: 2 }
  ]
  const rowsCookiesTable = _map(table, (row) => row)
  const rowsNecessaryCookiesTable = _map(necessaryCookiesTable, (row) => row)

  return (
    <CookiesWrapper>
      <H3 variant='boxTitle'>{`Cookies`}</H3>
      <ColoredLineBreak dark />
      {_map(section, (item, index) => <Content key={index}>{RichText.render(item.content)}</Content>)}
      <AnalyticsToggleButton />
      <ColoredLineBreak light />
      <H3 variant='boxTitle'>{`Necessary Cookies`}</H3>
      <Table
        columns={columns}
        rows={rowsNecessaryCookiesTable} />
      <ColoredLineBreak light />
      <H3 variant='boxTitle'>{`Additional Cookies`}</H3>
      <Table
        columns={columns}
        rows={rowsCookiesTable} />
    </CookiesWrapper>
  )
}

const WhoWeAreSection = ({ section }) => {
  return (
    <>
      <H3 variant='boxTitle'>{`Who we are`}</H3>
      <ColoredLineBreak dark />
      {RichText.render(section)}
    </>
  )
}

const OtherWebsitesSection = ({ section }) => {
  return (
    <>
      <H3 variant='boxTitle'>{`Other websites`}</H3>
      <ColoredLineBreak dark />
      <P>{section}</P>
    </>
  )
}



const Legal = ({ data, location }) => {
  const doc = data.prismic.allLegals.edges.slice(0, 1).pop();
  const pathName = location.pathname
  if (!doc) return null;

  // Main content
  const columnOne = _get(doc.node, 'column_one')

  // Cookies content
  const cookiesSection = _get(doc.node, 'cookies_section', null)
  const necessaryCookiesTable = _get(doc.node, 'necessary_cookies_table', null)
  const cookiesTable = _get(doc.node, 'cookies_table', null)

  // Other websites content
  const otherWebsitesContent = _get(doc.node, 'other_websites_content', null)

  // Who we are content
  const whoWeAreContent = _get(doc.node, 'who_we_are_content', null)

  return (
    <Layout transparentHeader pathName={pathName}>
      <SEO
        title={doc.node.meta_title}
        description={doc.node.meta_description}
        keywords={doc.node.meta_keywords}
        image={doc.node.meta_image}
				url={_get(location, 'href')}
        meta={doc.node.meta}
        noIndex={doc.node.no_index} />
      <Container>
        <H1 variant='primary'>{_get(doc, 'node.page_title')}</H1>
        <ColumnsWrapper>
          <LeftColumnWrapper>
            {/*  SECTION:  Main content */}
            {_size(columnOne) > 0 && (
              <MainContent
                section={columnOne} />
            )}
            {/*  SECTION:  Cookies */}
            {_size(cookiesSection) > 0 && (
              <CookiesSection
                section={cookiesSection}
                necessaryCookiesTable={necessaryCookiesTable}
                table={cookiesTable} />
            )}
            {/*  SECTION: Other website */}
            {otherWebsitesContent && (
              <OtherWebsitesSection
                section={otherWebsitesContent} />
            )}
          </LeftColumnWrapper>
          <RightColumnWrapper>
            {/*  SECTION:  Who we are */}
            {_size(whoWeAreContent) > 0 && (
              <WhoWeAreSection
                section={whoWeAreContent} />
            )}
          </RightColumnWrapper>
        </ColumnsWrapper>
      </Container>
    </Layout>
  )
}

export default Legal
