import React from 'react'
import styled from 'styled-components'
import _map from 'lodash/map'

import media from '../styles/media'

const TableWrapper = styled.table`
  border-spacing: .2rem;
  border-collapse: separate;
  table-layout: fixed;
  ${media.greaterThan('tablet')`
    table-layout: auto;
  `}
  ${media.greaterThan('desktop')`
    table-layout: auto;
  `}
`

const TableHead = styled.thead`

  
`
const TableBody = styled.tbody`
  
`

const TableHeadCell = styled.td`
  font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #DFDFDF;
  padding: 20px;
`

const TableBodyCell = styled.td`
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #F1F1F1;
  padding: 20px;
  white-space: pre-wrap;
`

const Table = ({ columns, rows }) => {
  let keys = _map(columns, 'key')
  return (
    <TableWrapper>
      <TableHead>
        <tr>
          {_map(columns, ({ key, value }) => <TableHeadCell key={key}>{value}</TableHeadCell>)}
        </tr>
      </TableHead>
      <TableBody>
        {_map(rows, (row, index) => (
          <tr key={index}>
            {_map(keys, (key) => <TableBodyCell key={key}>{row[key]}</TableBodyCell>)}
          </tr>))}
      </TableBody>
    </TableWrapper>
  )
}

export default Table;